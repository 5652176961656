import React, { SelectHTMLAttributes } from 'react';

import { Container } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label: string;
  options: Array<{
    value: string;
    label: string;
  }>;
  error: boolean;
  errorMsg: string;
}

const Select: React.FC<SelectProps> = ({
  name,
  label,
  error,
  errorMsg,
  options,
  ...rest
}) => {
  return (
    <Container>
      <label htmlFor={name}> {label} </label>
      <select id={name} value="" {...rest}>
        <option value="" disabled hidden>
          Selecione uma opção
        </option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="container-error">{error && <span>{errorMsg}</span>}</div>
    </Container>
  );
};

export default Select;
