import React from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import { Container } from './styles';

import Layout from '../../components/Layout';
import Jumbotron from '../../components/Jumbotron';
import Input from '../../components/Input';
import Select from '../../components/Select';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import JumbotronSmaller from '../../components/Jumbotron-smaller';
import Message from '../../components/Message';

import { optionsSelect } from '../../content/pages/Contact';

import IcCheck from '../../assets/images/ic_check.svg';

import handleValidate from '../../helpers/validations/formValidations';
import templateEmail from '../../helpers/templates/email';

const ContactPage: React.FC = () => {
  const recaptchaRef = React.useRef();
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [fone, setFone] = React.useState('');
  const [select, setSelect] = React.useState('');
  const [textarea, setTextarea] = React.useState('');
  const [recaptcha, setRecaptcha] = React.useState('');
  const [validateFields, setValidateFields] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  // const simulateMouseClickelement = () => {
  //   let element = document.querySelector('iframe');
  //   console.log('simulateMouseClickelement', element);
  // };

  React.useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])

  const getIcon = icon =>{
    switch (icon) {
      case 'email':
        return (
          <svg id="alternate_email_black_24dp_1_" data-name="alternate_email_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <path id="Path_6062" data-name="Path 6062" d="M0,0H32V32H0Z" fill="none"/>
            <path id="Path_6063" data-name="Path 6063" d="M16.292,2.039A13.322,13.322,0,0,0,2.039,16.292,13.588,13.588,0,0,0,15.745,28.665h4.92a1.333,1.333,0,1,0,0-2.667H15.772A11.032,11.032,0,0,1,5,17.959,10.7,10.7,0,0,1,17.945,5.012,11,11,0,0,1,26,15.772v1.467a2.155,2.155,0,0,1-2,2.093,2.155,2.155,0,0,1-2-2.093V15.572a6.818,6.818,0,0,0-5.68-6.827,6.674,6.674,0,1,0,3.733,11.307,4.929,4.929,0,0,0,5.733,1.613,4.747,4.747,0,0,0,2.88-4.453V15.759A13.591,13.591,0,0,0,16.292,2.039Zm-.96,17.293a4,4,0,1,1,4-4A3.995,3.995,0,0,1,15.332,19.332Z" transform="translate(0.668 0.668)" fill="#cd0"/>
          </svg>
        );
      case 'chat':
        return (
          <svg id="question_answer_black_24dp" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <path id="Path_6060" data-name="Path 6060" d="M0,0H32V32H0Z" fill="none"/>
            <path id="Path_6061" data-name="Path 6061" d="M20.2,4.8v9.8H6.438l-.826.826-.812.812V4.8H20.2M21.6,2H3.4A1.4,1.4,0,0,0,2,3.4V23l5.6-5.6h14A1.4,1.4,0,0,0,23,16V3.4A1.4,1.4,0,0,0,21.6,2Zm7,5.6H25.8V20.2H7.6V23A1.4,1.4,0,0,0,9,24.4H24.4L30,30V9A1.4,1.4,0,0,0,28.6,7.6Z" fill="#cd0"/>
          </svg>
        );
      case 'whats':
        return (
          <svg id="Digital_Glyph_White_Whatsapp" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <rect id="Rectangle_831" data-name="Rectangle 831" width="32" height="32" fill="none"/>
            <path id="Digital_Glyph_White" d="M20.4,3.488A11.9,11.9,0,0,0,1.687,17.837L0,24l6.3-1.654a11.877,11.877,0,0,0,5.683,1.447h.005A11.9,11.9,0,0,0,20.4,3.488Zm-8.412,18.3h0a9.872,9.872,0,0,1-5.031-1.378L6.6,20.193l-3.741.981,1-3.648-.235-.374a9.885,9.885,0,1,1,8.374,4.632Zm5.422-7.4c-.3-.149-1.758-.867-2.031-.967s-.47-.149-.668.149-.767.967-.941,1.165-.347.223-.644.074a8.125,8.125,0,0,1-2.39-1.475,8.956,8.956,0,0,1-1.653-2.059c-.173-.3-.018-.458.13-.606s.3-.347.446-.521a2.025,2.025,0,0,0,.3-.5.547.547,0,0,0-.024-.521c-.074-.149-.668-1.611-.916-2.206-.241-.579-.486-.5-.668-.51s-.371-.01-.57-.01a1.091,1.091,0,0,0-.793.372,3.333,3.333,0,0,0-1.04,2.479,5.782,5.782,0,0,0,1.213,3.074,13.253,13.253,0,0,0,5.076,4.487,17.043,17.043,0,0,0,1.694.626,4.071,4.071,0,0,0,1.872.118,3.061,3.061,0,0,0,2.006-1.413,2.487,2.487,0,0,0,.173-1.413c-.074-.124-.273-.2-.57-.347Z" transform="translate(4.072 4)" fill="#cd0" fill-rule="evenodd"/>
          </svg>
        );
      case 'Denúncia':
        return (
          <svg id="support_agent_black_24dp_1_" data-name="support_agent_black_24dp (1)" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g id="Group_7710" data-name="Group 7710">
              <rect id="Rectangle_833" data-name="Rectangle 833" width="32" height="32" fill="none"/>
              <rect id="Rectangle_834" data-name="Rectangle 834" width="32" height="32" fill="none"/>
            </g>
            <g id="Group_7712" data-name="Group 7712" transform="translate(2.667 4)">
              <g id="Group_7711" data-name="Group 7711">
                <path id="Path_6064" data-name="Path 6064" d="M27.333,15.293a12,12,0,1,0-24,.08A2.624,2.624,0,0,0,2,17.667v2.667A2.675,2.675,0,0,0,4.667,23h0A1.337,1.337,0,0,0,6,21.667V15.253a9.586,9.586,0,0,1,9.04-9.72,9.343,9.343,0,0,1,9.627,9.333v9.467H15.333A1.337,1.337,0,0,0,14,25.667h0A1.337,1.337,0,0,0,15.333,27h9.333a2.675,2.675,0,0,0,2.667-2.667V22.707a2.465,2.465,0,0,0,1.333-2.187V17.453A2.455,2.455,0,0,0,27.333,15.293Z" transform="translate(-2 -3)" fill="#cd0"/>
                <circle id="Ellipse_66" data-name="Ellipse 66" cx="1.333" cy="1.333" r="1.333" transform="translate(8 12)" fill="#cd0"/>
                <circle id="Ellipse_67" data-name="Ellipse 67" cx="1.333" cy="1.333" r="1.333" transform="translate(16 12)" fill="#cd0"/>
                <path id="Path_6065" data-name="Path 6065" d="M22,12.707A8.053,8.053,0,0,0,6.025,14.6,10.766,10.766,0,0,0,12.5,6.747,10.716,10.716,0,0,0,22,12.707Z" transform="translate(-0.665 -2)" fill="#cd0"/>
              </g>
            </g>
          </svg>
        );
      case 'ouvidoria':
        return (
          <svg id="campaign_black_24dp" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g id="Group_7713" data-name="Group 7713">
              <rect id="Rectangle_835" data-name="Rectangle 835" width="32" height="32" fill="none"/>
            </g>
            <path id="Path_6066" data-name="Path 6066" d="M18,12.333h0a1.337,1.337,0,0,0,1.333,1.333H22a1.337,1.337,0,0,0,1.333-1.333h0A1.337,1.337,0,0,0,22,11H19.333A1.337,1.337,0,0,0,18,12.333Z" transform="translate(6 3.667)" fill="#cd0"/>
            <path id="Path_6067" data-name="Path 6067" d="M16.656,16.962a1.288,1.288,0,0,0,.267,1.827c.707.52,1.453,1.08,2.16,1.613a1.3,1.3,0,0,0,1.84-.267c0-.013.013-.013.013-.027a1.3,1.3,0,0,0-.267-1.84c-.707-.533-1.453-1.093-2.147-1.613a1.324,1.324,0,0,0-1.853.28A.047.047,0,0,1,16.656,16.962Z" transform="translate(5.464 5.465)" fill="#cd0"/>
            <path id="Path_6068" data-name="Path 6068" d="M20.944,4.95c0-.013-.013-.013-.013-.027a1.307,1.307,0,0,0-1.84-.267c-.707.533-1.467,1.093-2.16,1.627a1.3,1.3,0,0,0-.253,1.84c0,.013.013.013.013.027a1.305,1.305,0,0,0,1.84.267c.707-.52,1.453-1.093,2.16-1.627A1.326,1.326,0,0,0,20.944,4.95Z" transform="translate(5.469 1.464)" fill="#cd0"/>
            <path id="Path_6069" data-name="Path 6069" d="M10,10H4.667A2.675,2.675,0,0,0,2,12.667v2.667A2.675,2.675,0,0,0,4.667,18H6v4a1.337,1.337,0,0,0,1.333,1.333h0A1.337,1.337,0,0,0,8.667,22V18H10l6.667,4V6Z" transform="translate(0.667 2)" fill="#cd0"/>
            <path id="Path_6070" data-name="Path 6070" d="M16,13.117A5.973,5.973,0,0,0,14,8.65v8.92A5.923,5.923,0,0,0,16,13.117Z" transform="translate(4.667 2.883)" fill="#cd0"/>
          </svg>
        );

    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setValidateFields(true);

    if (!!name && !!email && !!fone && !!select && !!recaptcha) {

      const emailFormated = templateEmail({ name, email, fone, select, textarea })

      await axios.post("https://app-consig.zipdin.com.br/app-consig/services/email/send", emailFormated);


      setShowMessage(true);
      setValidateFields(false);

      setName('');
      setEmail('');
      setFone('');
      setSelect('');
      setTextarea('');

      return;
    }
  };

  return (
    <>
      <Layout>
        <Container>
          <section className="bg-header-contact"></section>
          <section className="container pos-relative">
            <Jumbotron
              className="jumbotron-contact"
              title="Fale conosco"
              text="Informe o motivo do seu contato que rapidamente entraremos em contato."
            />
            <form className="form-contact" onSubmit={handleSubmit}>
              <div className="input-group">
                <div className="input-block">
                  <Input
                    name="name"
                    type="text"
                    label="Nome completo do responsável"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    error={validateFields && handleValidate(!name)}
                    errorMsg="Precisamos dessa informação."
                  />
                </div>
                <div className="input-block">
                  <Input
                    name="email"
                    type="email"
                    label="E-mail"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    error={validateFields && handleValidate(!email)}
                    errorMsg="Precisamos de um e-mail válido."
                  />
                </div>
              </div>
              <div className="input-group">
                <div className="input-block">
                  <Input
                    name="fone"
                    type="tel"
                    label="Telefone com DDD"
                    value={fone}
                    onChange={e => setFone(e.target.value)}
                    error={validateFields && handleValidate(!fone)}
                    errorMsg="Precisamos de um telefone válido"
                  />
                </div>
                <div className="input-block">
                  <Select
                    name="contact"
                    label="Motivo do contato"
                    error={validateFields && handleValidate(!select)}
                    errorMsg="Esta informação é muito importante para nós."
                    value={select}
                    onChange={e => setSelect(e.target.value)}
                    options={optionsSelect}
                  />
                </div>
              </div>
              <Textarea
                name="textarea"
                label="Mensagem (opcional)"
                value={textarea}
                onChange={e => setTextarea(e.target.value)}
              />
              <div className="form__recaptcha">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LcQqMQZAAAAAN79k2S50sBPmu_eyl0dSAhm0nHl"
                  onChange={e => setRecaptcha(e)}
                />
              </div>

              <div className="button-block">
                <Button type="submit">Enviar</Button>
              </div>
            </form>
          </section>
          <section className="section-cards-contact">
            <div className="container">
              <JumbotronSmaller
                icon={getIcon('email')}
                className="card noBefore"
                title="E-mail"
                text="Quer enviar um e-mail? Estamos à disposição para auxiliá-lo por meio do nosso endereço digital."
              >
                <address>
                  <a
                    target="blank"
                    href="mailto:atendimento@zipdin.com.br"
                    className="content-card"
                  >
                    atendimento@zipdin.com.br
                  </a>
                </address>
              </JumbotronSmaller>
              {/* <JumbotronSmaller
                icon={getIcon('chat')}
                className="card noBefore"
                title="Chat"
                text="Precisa de assistência imediata? Converse conosco agora mesmo através do chat Zipdin."
              >
                <address>
                  <a
                    target="blank"
                    className="content-card"
                    onClick={simulateMouseClickelement()}
                  >
                    Acessar chat on-line
                  </a>
                </address>
              </JumbotronSmaller> */}
              <JumbotronSmaller
                icon={getIcon('whats')}
                className="card noBefore"
                title="Whatsapp"
                text="Envie uma mensagem para o nosso número no WhatsApp, e nossa equipe estará pronta para ajudar."
              >
                <address>
                  <a
                    target="blank"
                    href="https://wa.me/21996664895"
                    className="content-card"
                  >
                    Fale pelo Whatsapp (21 99666 4895)
                  </a>
                </address>
              </JumbotronSmaller>
              <JumbotronSmaller
                icon={getIcon('Denúncia')}
                className="card noBefore"
                title="Denúncia"
                text="Precisa falar sobre algo que vai contra as boas práticas dos negócios? Entre em contato para ajudar no combate à corrupção, fraudes, lavagem de dinheiro, discriminação e outras práticas criminosas."
              >
                <address>
                  <a
                    target="blank"
                    href="mailto:denuncia@zipdin.com.br"
                    className="content-card"
                  >
                    denuncia@zipdin.com.br
                  </a>
                </address>
              </JumbotronSmaller>
              <JumbotronSmaller
                icon={getIcon('ouvidoria')}
                className="card noBefore"
                title="Ouvidoria"
                text="Nossa Ouvidoria atua quando suas solicitações não são atendidas através do SAC. As reclamações são tratadas com total independência e imparcialidade e acompanhadas de perto até a sua efetiva conclusão."
              >
                <address>
                  <a
                    target="blank"
                    href="mailto:ouvidoria@zipdin.com.br"
                    className="content-card"
                  >
                    ouvidoria@zipdin.com.br
                  </a>
                </address>
                <address>
                  <a
                    target="blank"
                    href="tel:2121376032"
                    className="content-card">
                    (21) 2137-6032
                  </a>
                </address>
              </JumbotronSmaller>
            </div>
          </section>
        </Container>
      </Layout>
      <Message
        onClick={() => setShowMessage(false)}
        state={showMessage}
        icon={IcCheck}
        text="Sua mensagem foi enviada com sucesso! Por favor, aguarde o nosso contato."
        buttonText="OK"
      />
    </>
  );
};

export default ContactPage;
