import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.main`
  font-family: 'Poppins', sans-serif;

  .bg-header-contact {
    ${mediaQuery.desktop`
      background-color: ${({ theme }) => theme.bg.second};
      height: 320px;
    `}
  }

  .container.pos-relative {
    position: relative;
    top: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    background-color: white;

    ${mediaQuery.desktop`
      background-color: white;
      // box-shadow: 0px 3px 6px #00000029;
      width: 850px;
      // height: 932px;
      top: -175px;
    `}
  }

  .jumbotron-contact {
    padding: 38px;
    width: 82%;
    margin: 38px auto;

    ${mediaQuery.desktop`
      width: inherit;
      margin: inherit;
      padding: 70px 80px 50px;
    `}

    p {
      max-width: 490px;
      font-size: 16px;
      font-weight: 400;

      ${mediaQuery.desktop`
        font-size: 18px;
      `}
    }
  }

  .form-contact {
    padding: 80px 36px;
    background-color: white;

    ${mediaQuery.desktop`
        padding: 0 80px 80px;
    `}

    .button-block {
      display: flex;
      justify-content: center;
    }

    button {
      margin-top: 61px;
      width: 100%;
      border: 2px solid ${({ theme }) => theme.font.third};
      min-height: 53px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0px;
      text-transform: uppercase;

      ${mediaQuery.desktop`
        width: 122px;
      `}
    }

    .form__recaptcha {
      margin-top: 50px;

      display: flex;
      justify-content: center;
    }
  }

  .input-group {
    display: block;

    ${mediaQuery.desktop`
      display: flex;
      justify-content: space-between;
    `}

    .input-block {
      width: 100% !important;

      ${mediaQuery.desktop`
        width: 320px !important;
      `}
    }
  }

  .section-cards-contact {
    width: 100%;
    background-color: ${({ theme }) => theme.bg.fifth};
    padding: 80px 0;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      ${mediaQuery.desktop`
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-items: stretch;
      `}
    }

    ${mediaQuery.desktop`
      padding: 150px 0;
      margin-top: -125px
    `}

    .card {
      width: 100% !important;
      margin: 0 0 33px !important;
      font-size: 12px;

      ${mediaQuery.desktop`
        width: 369px !important;
        margin: 0 1rem 1rem 0 !important;
      `}

      h3 {
        ${mediaQuery.desktop`
            margin-bottom: 0px;
        `}
      }

      P {
        margin: 10px 0;
        font-size: 12px;

        ${mediaQuery.desktop`
            font-size: 16px;
        `}
      }

      a {
            ${mediaQuery.desktop`
              font-size: 15.8px;
              text-decoration: underline;
            `}
          }

      &:nth-child(3) {
        margin-right: 0px !important;
      }

      address {
        ${mediaQuery.desktop`
          font-size: 16px;
        `}
        
        .content-card {
          margin-top: auto;
          color: ${({ theme }) => theme.font.third};
          font-family: 'Poppins', sans-serif;
          font-style: normal;
        }

        color: ${({ theme }) => theme.font.primary};
        font-style: normal;
      }
    }
  }
`;
