type templateEmailProps = {
  name: string;
  email: string;
  fone: string;
  select: string;
  textarea: string;
}

export default function templateEmail({ name, email, fone, select, textarea }: templateEmailProps) {
  const mensage = {
    assunto: "Cadastro Empresa",
    mensagem: "Olá, você recebeu um novo pré-cadastro a partir do site da Zipdin:<br><br>:" +
      "<br>Nome: " + name +
      "<br>Endereço de e-mail: " + email +
      "<br>Número de telefone celular: " + fone +
      "<br>Motivo do contato: " + select +
      "<br>Mensagem: " + textarea +
      "<br>",
    email: "atendimento@zipdin.com.br"
  };

  return mensage;
}