import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  letter-spacing: 0.32px;

  display: flex;
  flex-direction: column;

  label {
    color: ${({ theme }) => theme.font.primary};
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    letter-spacing: 0.26px;
  }

  select {
    height: 23px;
    border: none;
    background-color: ${({ theme }) => theme.bg.primary};
    font-size: 16px;
    color: ${({ theme }) => theme.font.second};
    position: relative;
    left: -4px;

    option {
      color: ${({ theme }) => theme.font.third};
      padding: 10px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  select:active {
    outline: none;
    border: none;
  }

  .container-error {
    height: 19px;
    font-size: 13px;
    color: ${({ theme }) => theme.font.error};
    border-top: 2px solid #0002;
    padding-top: 7px;
    margin-top: 6px;
  }
`;
