export const optionsSelect = [
  {
    value: 'Bank as a Service',
    label: 'Bank as a Service',
  },
  {
    value: 'Consignado as a Service',
    label: 'Consignado as a Service',
  },
  {
    value: 'Crédito PJ as a Service',
    label: 'Crédito PJ as a Service',
  },
  {
    value: 'Crédito Consignado Privado',
    label: 'Crédito Consignado Privado',
  },
  {
    value: 'Parceria',
    label: 'Parceria',
  },
  {
    value: 'SAC',
    label: 'SAC',
  },
];
